<template>
  <div class="newList">
    <div class="page_header_img">
      <!-- <Banner :bannerList="bannerList"></Banner> -->
      <img :src="bannerList[0].pic" class="bannerImg" />
    </div>
    <div class="page_body_con">
      <div class="margin-tb-35 flex-space-center">
        <div class="page_location">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">
              <i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>技术支持</el-breadcrumb-item>
            <!-- <el-breadcrumb-item>{{ currentTxt }}</el-breadcrumb-item> -->
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="page_body_con moment-row">
      <el-row class="flex">
        <el-col :span="20" class="white_bg">
          <el-row :gutter="20">
            <el-col
              class="itembox flex animation-slideInLeft "
              :span="6"
              v-for="(o, index) in list"
              :key="o.id"
            >
              <div class="top" @mouseenter="selectchoose(index)" @mouseleave="outchoose(index)">
                <img :src="o.pic" class="iconImg" />
                <p>
                  <span class="line9" v-html="o.content"></span>
                  <button
                    @click="bindDownloadBtn(o.file)"
                    v-if="
                      (o.download_type == 1 && o.file != '') ||
                        (o.download_type == 2 && o.file != '')
                    "
                  >
                    立即下载
                  </button>
                  <button @click="bindDownloadBtn(o.file)" v-else-if="o.download_type == 3">
                    立即观看
                  </button>
                  <span v-else></span>
                </p>
              </div>
              <div class="footer line1">{{ o.title }}</div>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="4" class="support_lt">
          <div class="page_title_name ta-l">
            <h3>技术支持</h3>
            <span>TECHNICAL SUPPORT</span>
          </div>
          <ul>
            <li :class="[curtab == 1 ? 'active' : '']" @click="bindType(1)">
              配置工具
            </li>
            <li :class="[curtab == 2 ? 'active' : '']" @click="bindType(2)">
              产品说明书
            </li>
            <li :class="[curtab == 3 ? 'active' : '']" @click="bindType(3)">
              操作视频
            </li>
          </ul>
          <img :src="require('@/assets/images/support/bar.png')" />
        </el-col>
      </el-row>
    </div>
    <div class="page_body_con pagination ta-c">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[8, 10, 20, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import SelectGroup from "@/components/SelectGroup.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: {
    SelectGroup,
    Banner,
  },
  data() {
    return {
      curtab: 1,
      page: 1,
      limit: 8,
      total: 0,
      list: [],
      bannerList: [
        { src: "", pic: "" },
        // { src: require("@/assets/images/header/banner2.png") },
      ],
      downloadImg: [
        { src: require("@/assets/images/support/1.png") },
        { src: require("@/assets/images/support/2.png") },
        { src: require("@/assets/images/support/1.png") },
        { src: require("@/assets/images/support/1.png") },
        { src: require("@/assets/images/support/1.png") },
      ],
    };
  },
  activated() {
    this.getDownloadList();
    setTimeout(() => {
      this.bannerList = this.$store.state.bannerList.filter((item) => {
        let sonarr = item.type.split("|");
        return sonarr.includes("6");
      });
    }, 500);
  },
  methods: {
    selectchoose(e) {
      // this.current = e;
    },
    getBannerList() {
      this.$http({
        method: "get",
        url: "banner_list",
        params: {
          limit: 10,
          page: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let bannerList = res.data.data;
          let arr = [];
          bannerList.forEach((element) => {
            element.src = element.pic;
            arr.push(element);
          });
          this.bannerList = arr;
          // console.log(res.data[0].children)
        }
      });
    },
    bindDownloadBtn(url) {
      console.log(url);
      // let a = document.createElement("a");
      // a.href = url;
      // a.click();
      window.open(url, "_blank");
      // window.location.href = url
    },
    outchoose(e) {
      // this.curtab=0
    },
    bindDetail() {
      this.$router.push({
        path: "/news/detail",
      });
    },
    bindType(e) {
      this.curtab = e;
      this.page = 1;
      this.list = [];
      this.getDownloadList();
    },
    bindchange(e) {
      console.log("父", e);
    },
    getDownloadList() {
      this.$http({
        method: "get",
        url: "website_content_list",
        params: {
          limit: this.limit,
          page: this.page,
          type: 4,
          download_type: this.curtab,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.total = res.data.count;
          this.list = res.data.data;
          // console.log(res.data[0].children)
        }
      });
    },
    bindProductDetail(e) {
      console.log("go1");
      console.log("go", e);

      this.$router.push({
        path: "/product/productdetail",
        query: {
          //query是个配置项
          age: 20,
        },
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.getDownloadList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getDownloadList();
    },
  },
};
</script>

<style lang="scss" scoped>
.newList {
  background: #fff;
}
.page_title > span {
  border: none;
}
.button {
  padding: 0;
  float: right;
}

.moment-row {
  text-align: center;
  // padding-top: 30px;
  // padding-bottom: 72px;
  padding: 0px 15px 60px;
  background: #ffffff;
  // overflow: hidden;
  // float: right;
  .title {
    margin-top: 0;
    font-size: 42px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    height: 42px;
    line-height: 42px;
    margin-bottom: 30px;
  }
  .info {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    text-align: left;
  }
  .support_lt {
    padding: 0 15px;
    margin: 0 0 30px 15px;
    .title {
      h3 {
        width: 221px;
        height: 82px;
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        font-style: italic;
        color: #000000;
        line-height: 42px;
      }
      span {
        width: 176px;
        height: 43px;
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        color: #000000;
        line-height: 46px;
        margin-top: 10px auto 30px;
      }
    }
    ul {
      margin: 30px auto 30px;
      background: #f4f4f4;
      li {
        height: 50px;
        width: 100%;
        background: #f4f4f4;
        color: #333;
        // margin: 0px 0px 15px 15px;
        line-height: 50px;
        font-size: 16px;
        text-align: center;
        border-bottom: 1px solid #fff;
      }
      .active {
        background: #006acd;
        color: #fff;
      }
    }
    img {
      margin: 30px 0;
      width: 100%;
      height: 300px;
    }
  }
  .itembox {
    cursor: pointer;
    height: 360px;
    // width: 20%;

    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    align-items: flex-start;
    // margin-right: 15px;
    .top {
      background: #f4f4f4;
      height: 320px;
      display: flex;
      align-items: center;
      // flex-direction: column;
      position: relative;
      width: 100%;
      img {
        display: inline-block;
        width: 200px;
        height: 200px;
        margin: 30px auto;
      }
      p {
        display: none;
        button {
          width: 68px;
          height: 24px;
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          position: absolute;
          bottom: 15px;
        }
      }
    }
    .footer {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #e4e4e4;
    }
  }
  .itembox:hover {
    p {
      position: absolute;
      height: 320px;
      background: rgba(32, 126, 255, 0.8);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      color: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      top: 0;
      z-index: 666;
      justify-content: center;
      padding: 0 30px;
      line-height: 28px;
    }
    .footer {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #999999;
    }
  }
}
.moment-rt {
  padding: 0px 15px 30px 15px;
  .page_title {
    h6 {
      // margin: 30px 0 0px 30px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      font-style: italic;
      color: #000000;
    }
    span {
      // margin-left: 30px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #000000;
      line-height: 60px;
      height: 60px;
      display: inline-block;
    }
  }
  .hort {
    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      line-height: 46px;
      height: 46px;
    }
    .hort_list {
      display: flex;
      flex-direction: column;
      h6 {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
        height: 32px;
      }
      .gray_bg {
        padding: 15px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
